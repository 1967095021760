<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card raised outlined>
              <v-dialog persistent v-model="dialog" max-width="1000"
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="primary" dark class="ml-auto ma-3 mr-3" @click="refreshData(true)">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn color="primary" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="primary"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit "{{ editedItem.email }}"</span>
                      <span v-else>Create User</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-form
                        class="custom_form"
                        ref="form"
                        v-model="validationRules.valid"
                        lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.name" :counter="300"
                                        maxLength="300"
                                        :rules="validationRules.nameRules"
                                        label="Name *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="!editedItem.is_edit">
                          <v-text-field v-model="editedItem.email" :counter="100"
                                        clearable
                                        outlined
                                        dense
                                        :rules="validationRules.emailRules" label="Email *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.department"
                                        maxLength="100"
                                        :counter="100"
                                        label="Department"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4">
                          <v-text-field v-model="editedItem.password" :counter="20"
                                        clearable
                                        outlined
                                        dense
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show ? 'text' : 'password'"
                                        @click:append="show = !show" label="Password"
                          ></v-text-field>
                        </v-col>
                        <!--                              <v-col cols="12" sm="4" v-if="userTypeIsInd">-->
                        <!--                                <v-select clearable-->
                        <!--                                          outlined-->
                        <!--                                          dense v-model="editedItem.gender" :items="genders"-->
                        <!--                                          item-text="title"-->
                        <!--                                          item-value="value"-->
                        <!--                                          label="Gender" required>-->
                        <!--                                </v-select>-->
                        <!--                              </v-col>-->
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-card-title>
                Users ({{ totalRecords }})
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0 custom-actions"
              >
                <template v-slot:item.name="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.name"></p>
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.email"></p>
                  </div>
                </template>
                <template v-slot:item.department="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.department"></p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn color="primary" @click="showEditDialog(item)" dark
                           class="mr-2">
                      Edit
                      <v-icon dark right small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn
                        @click="deleteItem(item)"
                        color="red" dark class="mr-2" v-if="$store.state.auth.user.type === 1">
                      Delete
                      <v-icon dark right small>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  USERS_LIST,
  CREATE_USER,
  UPDATE_USER,
  CLEAR_USER_ERRORS,
  DELETE_USER, GET_USER_DETAILS
} from "@/core/services/store/users.module";
import {bus} from "@/main";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: 'users',
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          name: 'name',
          filterable: false,
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          name: 'email',
          filterable: false,
          sortable: false
        },
        {text: 'Department', value: 'department', name: 'department', filterable: false, sortable: false},
        {text: 'Password', value: 'u_p', name: 'u_p', filterable: false, sortable: false},
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 300) || "Must be less than or equal to 300 characters"
        ],
        typeOfUserRules: [
          v => !!v || "This field is required"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
        permissionRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Permission(s)';
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      show: false,
      editedItem: {},
      typeOfUser: [
        {value: "0", title: 'Individual'},
        {value: "1", title: 'Institution/Company/Organization'}
      ],
      genders: [
        {value: "0", title: 'Male'},
        {value: "1", title: 'Female'}
      ],
      searchFormData: {
        fNameSearch: '',
        lNameSearch: '',
        phoneSearch: ''
      },
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_USER_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      if (!val) this.activeTab = 0;
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "User's Panel", route: "users"},
      {title: "Users"}
    ]);
    if (this.$route.query.hasOwnProperty('rel')) {
      this.searchFormData.phoneSearch = this.$route.query.rel
    }
    // this.refreshData(true);
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 3 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(USERS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        // if (this.$store.state.auth.user.type === 1) {
        //   if (loadDep) this.getAdminPermissions();
        // } else {
        //   if (loadDep) this.getVendorPermissions();
        // }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_USER_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        this.editedItem.is_edit = true;
        this.dialog = true;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_USER;
        let id = item.id;
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_USER;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_USER_ERRORS);
        // save the record
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          if (method === 'update_users') {
            this.showEditDialog(item);
          } else {
            this.dialog = false;
          }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          // this.editedItem.id = response.records.id
          // if (!id) {
          //   this.items.unshift(this.editedItem)
          // }
          // this.dialog = !this.dialog
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete?", clr: 'red', callback: function () {
          this.$store.dispatch(DELETE_USER, {
            'user_id': item.id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {
          fNameSearch: '',
          lNameSearch: '',
          phoneSearch: ''
        };
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>